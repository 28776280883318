// allowa
import allowa1 from '../images/projects/allowa--1.png'
import allowa2 from '../images/projects/allowa--2.png'
import allowa3 from '../images/projects/allowa--3.png'
import allowa4 from '../images/projects/allowa--4.png'
import allowa5 from '../images/projects/allowa--5.png'
import allowa6 from '../images/projects/allowa--6.png'

// fabriq
import fabriq1 from '../images/projects/fabriq--1.png'
import fabriq2 from '../images/projects/fabriq--2.png'
import fabriq3 from '../images/projects/fabriq--3.png'
import fabriq4 from '../images/projects/fabriq--4.png'
import fabriq5 from '../images/projects/fabriq--5.png'
import fabriq6 from '../images/projects/fabriq--6.png'
import fabriq7 from '../images/projects/fabriq--7.png'

// pacific
import pacific1 from '../images/projects/pacific--1.png'
import pacific2 from '../images/projects/pacific--2.png'
import pacific3 from '../images/projects/pacific--3.png'

// forkked
import forkked1 from '../images/projects/forkked--1.png'
import forkked2 from '../images/projects/forkked--2.png'
import forkked3 from '../images/projects/forkked--3.png'
import forkked4 from '../images/projects/forkked--4.png'

// hannah senesh
import hannah1 from '../images/projects/hannah--1.png'
import hannah2 from '../images/projects/hannah--2.png'
import hannah3 from '../images/projects/hannah--3.png'
import hannah4 from '../images/projects/hannah--4.png'

// kotka
import kotka1 from '../images/projects/ekotka--1.png'
import kotka2 from '../images/projects/ekotka--2.png'
import kotka3 from '../images/projects/ekotka--3.png'

export const photosAllowa = [
    {
        src: allowa1,
        width: 3,
        height: 2,
    },
    {
        src: allowa2,
        width: 3,
        height: 2,
    },
    {
        src: allowa3,
        width: 3,
        height: 2,
    },
    {
        src: allowa4,
        width: 3,
        height: 2,
    },
    {
        src: allowa5,
        width: 6,
        height: 3,
    },
    {
        src: allowa6,
        width: 6,
        height: 3,
    },
]

export const photosFabriq = [
    {
        src: fabriq1,
        width: 3,
        height: 2,
    },
    {
        src: fabriq2,
        width: 3,
        height: 2,
    },
    {
        src: fabriq3,
        width: 3,
        height: 2,
    },
    {
        src: fabriq4,
        width: 3,
        height: 2,
    },
    {
        src: fabriq5,
        width: 6,
        height: 3,
    },
    {
        src: fabriq6,
        width: 3,
        height: 2,
    },
    {
        src: fabriq7,
        width: 3,
        height: 2,
    },
]

export const photosForkked = [
    {
        src: forkked1,
        width: 5,
        height: 4,
    },
    {
        src: forkked2,
        width: 4,
        height: 4,
    },
    {
        src: forkked3,
        width: 2,
        height: 3,
    },
    {
        src: forkked4,
        width: 3,
        height: 2,
    },
]

export const photosPacific = [
    {
        src: pacific1,
        width: 3,
        height: 3,
    },
    {
        src: pacific2,
        width: 2,
        height: 1,
    },
    {
        src: pacific3,
        width: 5,
        height: 3,
    },
]

export const photosHannah = [
    {
        src: hannah1,
        width: 4,
        height: 2,
    },
    {
        src: hannah2,
        width: 3,
        height: 2,
    },
    {
        src: hannah3,
        width: 3,
        height: 2,
    },
    {
        src: hannah4,
        width: 4,
        height: 2,
    },
]

export const photosKotka = [
    {
        src: kotka1,
        width: 4,
        height: 2,
    },
    {
        src: kotka2,
        width: 3,
        height: 3,
    },
    {
        src: kotka3,
        width: 3,
        height: 2,
    },
]
