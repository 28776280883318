import React from 'react'
import '../sass/main.scss'

// Import components
import ContactContent from '../components/contactContent'
// import Map from "../components/map"

const Contact = () => (
    <div id="contact">
        <ContactContent />
        {/* <Map /> */}
    </div>
)

export default Contact
